import React from "react"
import styles from "./contactus.module.scss"
import Slide from "react-reveal/Slide"
import Linkedin from "../../assets/linkedin.svg"
import Footer from "../footer/footer"
import { SeeMoreButton } from "../seeMoreButton/seeMoreButton"
import tt from "../../helpers/translation"

const ContactUs = ({ title, subtitle, route, footer, lang }) => (
  <div className={styles.container}>
    <div>
      <h2>{title || "Contact us"}</h2>
      <p className={styles.subtitle}>
        {subtitle || "Start your project now, we are here to help!"}
      </p>
      <p>
        C/ Roger de Llúria, 118 3er 1a - 08037 BARCELONA
        <br />
        <br /> <br />
        <a
          href="https://www.linkedin.com/company/interficie-proyectos-interactivos"
          target="blank"
        >
          <Linkedin />
        </a>
      </p>

      <p>
        <SeeMoreButton contrast text={title || "Contact us"} sendTo={route} />
      </p>
    </div>
    {footer ? <Footer lang={lang} /> : <></>}
  </div>
)

export default ContactUs

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"

export const SeoComponent = ({
  language = "en",
  titleSeo = "Interficie Internet Services",
  description = "Interficie Internet Services develops innovative websites, apps and platforms for global brands, startups and organizations",
  keywords = "internet services",
  url = "/",
  alternateLanguage = "es",
  alternateUrl = "/es",
}) => {
  return (
    <Helmet>
      <html lang={language} />
      <meta property="og:title" content={titleSeo} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta
        property="og:image"
        content={
          "https://media-exp1.licdn.com/dms/image/C561BAQHYX3-RmKAp_g/company-background_10000/0?e=2159024400&v=beta&t=eUtjHmuoEpno3rswGYGBPYJmd7sgXQL-rT36Cf_Ibak"
        }
      />
      <meta name="keywords" content={keywords} />
      <meta
        name="google-site-verification"
        content="oYLwyDEH-2tkgjgOdzVtgm7SApNhvHd33xGotGSk3VI"
      />
      <title>{titleSeo}</title>
      <link rel="alternate" hrefLang={alternateLanguage} href={alternateUrl} />
      <link rel="canonical" href={url} />
    </Helmet>
  )
}

export default SeoComponent

import { Link } from "gatsby"
import React from "react"
import LogoWhite from "../../assets/logo-interficie-white.svg"
import Logo from "../../assets/logo-interficie.svg"
import { Menu } from "../menu/menu.jsx"
import styles from "./index.module.scss"
import PropTypes from "prop-types"
import allUrls from "../../urls.json"

const calculateUrls = (allUrls, language) =>
  allUrls.map(item => item[`url_${language}`])

const Header = ({ location, contrast, language, urls }) => {
  const urlsLang = calculateUrls(allUrls, language)
  let ourwork
  let wedo
  let about
  let careers
  let contact
  let digitalKit
  switch (language) {
    case "es":
      ourwork = "Proyectos"
      wedo = "Soluciones"
      about = "Nosotros"
      careers = "Posiciones"
      contact = "Contáctanos"
      digitalKit = "Kit Digital"
      break
    case "ca":
      ourwork = "Projectes"
      wedo = "Solucions"
      about = "Nosaltres"
      careers = "Posicions"
      contact = "Contacta'ns"
      digitalKit = "Kit Digital"
      break
    default:
      ourwork = "Our work"
      wedo = "Solutions"
      about = "About us"
      careers = "Careers"
      contact = "Contact us"
      digitalKit = "Kit Digital"
      break
  }

  const options = [
    {
      key: 1,
      text: <Link to={`${urlsLang[4]}/`}>{ourwork}</Link>,
      chosen: location === "ourwork",
    },
    {
      key: 2,
      text: <Link to={`${urlsLang[5]}/`}>{wedo}</Link>,
      chosen: location === "wedo",
    },
    {
      key: 3,
      text: <Link to={`${urlsLang[1]}/`}>{about}</Link>,
      chosen: location === "about",
    },
    {
      key: 4,
      text: <Link to={`${urlsLang[2]}/`}>{careers}</Link>,
      chosen: location === "careers",
    },
    {
      key: 5,
      text: <Link to={`${urlsLang[28]}/`}>{digitalKit}</Link>,
      chosen: location === "digitalKit",
    },
    {
      key: 6,
      text: <Link to={`${urlsLang[3]}/`}>{contact}</Link>,
      chosen: location === "contact",
    },
  ]

  return (
    <div className={styles.wrapper}>
      {contrast ? (
        <Link
          className={styles.logo_link}
          to={`${
            language === "en" ? "https://www.interficie.com/" : `/${language}/`
          }`}
        >
          <Logo className={styles.logo} />
        </Link>
      ) : (
        <Link
          className={styles.logo_link}
          to={`${
            language === "en" ? "https://www.interficie.com/" : `/${language}/`
          }`}
        >
          <LogoWhite className={styles.logo} />
        </Link>
      )}
      <Menu
        options={options}
        contrast={contrast}
        langSelector={urls}
        langActive={language}
      />
    </div>
  )
}

Header.propTypes = {
  location: PropTypes.string,
  contrast: PropTypes.bool,
}

Header.defaultProps = {
  contrast: false,
  location: "home",
}

export default Header

const en = require('../i18n/en.json');
const es = require('../i18n/es.json');
const ca = require('../i18n/ca.json');


const translate = function traduction(text, locale) {
  const showM = false;

  if (showM) {
    const regex = /[a-z0-9]/gi;
    const ret = text.replace(regex, 'M');
    return (locale + ret);
  }
  const jsonArray = Object.getOwnPropertyNames(en);
  let content;
  let property;
  for (let i = 0; i <= jsonArray.length; i++) {
    if (en[jsonArray[i]] === text) {
      property = jsonArray[i];
    }
  }
  if (property) {
    switch (locale) {
      case 'en':
        content = en[property];
        break;
      case 'es':
        content = es[property];
        if (content === undefined) content = en[property];
        break;
      case 'ca':
        content = ca[property];
        if (content === undefined) content = en[property];
        break;
      default:
        content = text;
        break;
    }
    return content;
  }
  // console.log('Not found "');
  // console.log(text);
  // console.log('"');
  return text;
};

// export default translate;
module.exports = translate;


import PropTypes from 'prop-types'
import React from "react"
import { Link } from "gatsby"
import styles from './index.module.scss'
import ArrowWhite from '../../assets/arrow-white-right.svg';
import ArrowBlue from '../../assets/arrow-blue-right.svg';

export const SeeMoreButton = ({ text, sendTo, contrast, disabled = false, disabledLight = false, type }) => {
  if (disabled) {
    return (
      <button className={styles[disabledLight ? `disabled_light` : `wrapper${disabled ? '_disabled' : ''}`]} disabled={disabled}>
        <div className={styles[`container${contrast ? '_contrast' : ''}`]}>
          {text || 'See our work'}
        </div>
      </button>)
  } else if (type) {
    return (
      <button className={styles[`wrapper${contrast ? '_contrast' : ''}`]} disabled={disabled} type={type}>
        <div className={styles[`container${contrast ? '_contrast' : ''}`]}>
          {text || 'See our work'}
          {contrast
            ? <ArrowBlue className={styles.arrow} />
            : <ArrowWhite className={styles.arrow} />
          }
        </div>
      </button>
    )
  }
  return (
    <Link className={styles.link} to={sendTo || "#/"} >
      <button className={styles[`wrapper${contrast ? '_contrast' : ''}`]} disabled={disabled}>
        <div className={styles[`container${contrast ? '_contrast' : ''}`]}>
          {text || 'See our work'}
          {contrast
            ? <ArrowBlue className={styles.arrow} />
            : <ArrowWhite className={styles.arrow} />
          }
        </div>
      </button>
    </Link>
  )
}

import { useState, useEffect } from "react"

export const useScrollPercentHandler = (start, end) => {
  // setting initial value to true
  const [percent, setPercent] = useState(0)

  // running on mount
  useEffect(() => {
    const onScroll = () => {
      const pos = window.scrollY
      if (pos <= start && percent > 0) {
        setPercent(0)
      } else if (pos > start && pos <= end) {
        setPercent((pos - start) / (end - start))
      } else if (pos > end && percent !== 1) {
        setPercent(1)
      }
    }

    // setting the event handler from web API
    document.addEventListener("scroll", onScroll)

    // cleaning up from the web API
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [percent, setPercent])

  return percent

}
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PropTypes from "prop-types"
import React from "react"
import styles from "./index.module.scss"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const sortLangs = (languages, languageActive) => {
  const array = [languageActive.toUpperCase()]
  languages.forEach(lan => {
    if (lan !== languageActive.toUpperCase()) array.push(lan)
  })
  return array
}

export const Menu = ({
  options,
  contrast,
  click,
  langSelector,
  langActive,
}) => {
  const languages = ["EN", "ES", "CA"]
  const orderedLangs = sortLangs(languages, langActive)
  const classes = useStyles()
  return (
    <div onClick={e => e.stopPropagation()} className={styles.wrapper}>
      {options.map((option, index) => (
        <div
          key={index}
          onClick={click ? () => click(false) : null}
          key={option.key}
          className={`${
            styles[`menu_option_box${contrast ? "_contrast" : ""}`]
          } 
        ${option.chosen ? styles.chosen : ""}`}
        >
          <span>{option.text}</span>
        </div>
      ))}

      {langSelector && (
        <React.Fragment>
          <div
            className={
              contrast
                ? styles.sub_menu_parent_contrast
                : styles.sub_menu_parent
            }
            tab-index="0"
          >
            <Link
              to={
                orderedLangs[0] === "EN" && langSelector.id === "HOME"
                  ? "/"
                  : `${langSelector[`url_${orderedLangs[0].toLowerCase()}`]}/`
              }
            >
              {orderedLangs[0]}
            </Link>

            <ul className={styles.sub_menu}>
              {orderedLangs.slice(1).map((item, index) => (
                <li key={index}>
                  <Link
                    to={
                      item === "EN" && langSelector.id === "HOME"
                        ? "/"
                        : `${langSelector[`url_${item.toLowerCase()}`]}/`
                    }
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={`${classes.root} ${styles.mobile_menu_lang}`}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {orderedLangs[0]}
                </Typography>
              </AccordionSummary>
              {orderedLangs.slice(1).map((item, index) => (
                <AccordionDetails key={index}>
                  <Link to={langSelector[`url_${item.toLowerCase()}`]}>
                    {item}
                  </Link>
                </AccordionDetails>
              ))}
            </Accordion>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

Menu.propTypes = {
  options: PropTypes.array.isRequired,
}

import React, { useState } from "react"
import { useScrollHandler } from "../../hooks/useScroll"
import ContactUS from "../ContactUs/ContactUs"
import CookiesBanner from "../CookiesBanner/CookiesBanner"
import Footer from "../footer/footer"
import HeaderMobile from "../header-mobile/header"
import Header from "../header/header"
import SeoComponent from "../SeoComponent"
import styles from "./layout.module.scss"

const defaultSeoData = {
  titleSeo: "Interficie Internet Services",
  description:
    "Interficie Internet Services develops innovative websites, apps and platforms for global brands, startups and organizations",
  keywords: "internet services",
  url: "/",
  alternateLanguage: "es",
  alternateUrl: "/es",
}

const getContactTranslated = lan => {
  switch (lan) {
    case "es":
      return {
        title: "Contáctanos",
        subtitle: "Empieza tu proyecto, estamos aquí para ayudarte!",
        route: "/es/contacto/",
      }
    case "ca":
      return {
        title: "Contacta'ns",
        subtitle: "Comença el teu projecte, estem aquí per ajudar-te!",
        route: "/ca/contacte/",
      }
    case "en":
      return {
        title: "Contact us",
        subtitle: "Start your project, we are here to help you!",
        route: "/contact-us/",
      }
    default:
      return {
        title: "Contact us",
        subtitle: "Start your project, we are here to help you!",
        route: "/contact-us/",
      }
  }
}

const Layout = ({
  children,
  footer,
  lan,
  location,
  contrast,
  seoData = defaultSeoData,
  urls,
  leftContactUs,
  noFooter = false,
}) => {
  const [openMenu, setOpenMenu] = useState(false)
  const { title, subtitle, route } = getContactTranslated(lan)

  const scroll = useScrollHandler()

  return (
    <React.Fragment>
      <SeoComponent {...seoData} />
      <div className={styles.wrapper}>
        <Header
          location={location}
          contrast={contrast}
          language={lan}
          urls={urls}
        />
        <HeaderMobile
          clickMenu={setOpenMenu}
          location={location}
          contrast={contrast}
          language={lan}
          scroll={scroll}
          urls={urls}
        />
        {!openMenu ? (
          <div className="main-container">
            {children}
            {!noFooter ? (
              <React.Fragment>
                <div
                  className={`${styles.white} ${
                    leftContactUs ? styles.left : ""
                  }`}
                >
                  <ContactUS
                    title={title}
                    subtitle={subtitle}
                    route={route}
                    lang={lan}
                  />
                </div>
                {footer ? footer() : <Footer lang={lan} />}
              </React.Fragment>
            ) : null}
          </div>
        ) : (
          <div className={styles.open_menu} />
        )}
      </div>
      <CookiesBanner location={location} language={lan} />
    </React.Fragment>
  )
}

export default Layout

import { Link } from "gatsby"
import { version } from "../../../package.json"
import React from "react"
import styles from "./index.module.scss"
import tt from "../../helpers/translation"
import urls from "../../urls"

const Footer = ({ location, clickMenu, contactUs, lang }) => {
  const [privacyUrls] = urls.filter(obj => {
    return obj.id === "COOKIES"
  })
  const [legalUrls] = urls.filter(obj => {
    return obj.id === "LEGAL"
  })
  const currentYear = new Date().getFullYear()
  return (
    <div className={styles.wrapper}>
      <ul>
        <li>©{currentYear} Interficie.</li>
        <li>
          <Link to={privacyUrls[`url_${lang}`]}>
            {tt("Cookies Policy", lang)}
          </Link>
        </li>
        <li>
          <Link to={legalUrls[`url_${lang}`]}>{tt("Legal Notices", lang)}</Link>
        </li>
      </ul>
      <p>v{version}</p>
    </div>
  )
}

export default Footer

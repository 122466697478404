import { Link } from "gatsby"
import React, { useCallback, useState } from "react"
import styles from "./cookiesBanner.module.scss"
import { useEffect } from "react"
import Cookies from "js-cookie"

const messages = {
  ca: {
    message:
      "Interficie processa informació sobre la seva visita utilitzant cookies que milloren tant el rendiment del nostre lloc web com l'experiència d'usuari. En escollir l'opció d'Acceptar a continuació, acceptes l'ús d'aquestes cookies.",
    accept: "Acceptar",
    deny: "Denegar",
    more: "Més informació",
    url: "/ca/politica-cookies",
  },
  en: {
    message:
      "Interficie processes information about your visit by using cookies that improve the performance of the website as well as the user experience. By clicking Accept, you accept the use of such cookies.",
    accept: "Accept",
    deny: "Deny",
    more: "More information",
    url: "/cookies-policy",
  },
  es: {
    message:
      "Interficie procesa información sobre tu visita utilizando cookies que mejoran el rendimiento de nuestro sitio web y la experiencia de usuario. Al eligir la opción de Aceptar a continuación, aceptas el uso de cookies.",
    accept: "Aceptar",
    deny: "Denegar",
    more: "Más información",
    url: "/cookies-policy",
  },
}

const loadCookieAck = () => {
  return typeof window === "undefined" ? undefined : Cookies.get("cookie-ack")
}

const CookiesBanner = ({ location, language }) => {
  // this following line and logic regarding initialRender will  solve issues regarding hydration
  const [initialRender, setInitialRender] = useState(true)
  const [cookieAck, _setCookieAck] = useState(loadCookieAck)
  const setCookieAck = useCallback(
    value => {
      Cookies.set("cookie-ack", value, { expires: 365 })
      _setCookieAck(value)
    },
    [_setCookieAck]
  )

  useEffect(() => {
    if (initialRender) {
      setTimeout(() => setInitialRender(false), 0)
    }
  }, [initialRender, setInitialRender])

  const accept = () => {
    setCookieAck("1")
    typeof loadGTM === "function" && loadGTM()
  }

  const deny = () => {
    setCookieAck("0")
  }

  const visible =
    !initialRender && cookieAck === undefined && location !== "cookies-policy"

  return (
    <div
      id="cookies-banner"
      className={`${styles.banner} ${visible ? styles.visible : styles.hidden}`}
    >
      <p>{messages[language].message}</p>
      <div className={styles.options}>
        <button onClick={accept}>{messages[language].accept}</button>
        <button onClick={deny}>{messages[language].deny}</button>
        <Link to={messages[language].url}>{messages[language].more}</Link>
      </div>
    </div>
  )
}

export default CookiesBanner

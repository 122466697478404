import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import LogoWhiteNA from "!!file-loader!../../assets/logo-interficie-white-no-animado.svg"
import LogoWhite from "!!file-loader!../../assets/logo-interficie-white.svg"
import Logo from "!!file-loader!../../assets/logo-interficie.svg"
import allUrls from "../../urls.json"
import { useScrollPercentHandler } from "../../hooks/useScrollPercent"

import styles from "./index.module.scss"
import { Menu } from "../menu/menu"

const calculateUrls = (allUrls, language) =>
  allUrls.map(item => item[`url_${language}`])
const HeaderMobile = ({
  location,
  clickMenu,
  contrast,
  language,
  scroll,
  urls,
}) => {
  const urlsLang = calculateUrls(allUrls, language)
  let ourwork
  let wedo
  let about
  let careers
  let contact
  let digitalKit
  switch (language) {
    case "es":
      ourwork = "Proyectos"
      wedo = "Soluciones"
      about = "Nosotros"
      careers = "Posiciones"
      contact = "Contáctanos"
      digitalKit = "Kit Digital"
      break
    case "ca":
      ourwork = "Projectes"
      wedo = "Solucions"
      about = "Nosaltres"
      careers = "Posicions"
      contact = "Contacta'ns"
      digitalKit = "Kit Digital"
      break
    default:
      ourwork = "Our work"
      wedo = "Solutions"
      about = "About us"
      careers = "Careers"
      contact = "Contact us"
      digitalKit = "Kit Digital"
      break
  }

  const scrollPerc = useScrollPercentHandler(50, 200)

  const [openMenu, setOpenMenu] = useState(false)
  const clickedMenu = newOpenMenu => {
    setOpenMenu(newOpenMenu)
    clickMenu(newOpenMenu)
  }
  const options = [
    {
      key: 1,
      text: <Link to={`${urlsLang[4]}/`}>{ourwork}</Link>,
      chosen: location === "ourwork",
    },
    {
      key: 2,
      text: <Link to={`${urlsLang[5]}/`}>{wedo}</Link>,
      chosen: location === "wedo",
    },
    {
      key: 3,
      text: <Link to={`${urlsLang[1]}/`}>{about}</Link>,
      chosen: location === "about",
    },
    {
      key: 4,
      text: <Link to={`${urlsLang[2]}/`}>{careers}</Link>,
      chosen: location === "careers",
    },
    {
      key: 5,
      text: <Link to={`${urlsLang[21]}/`}>{digitalKit}</Link>,
      chosen: location === "digitalKit",
    },
    {
      key: 6,
      text: <Link to={`${urlsLang[3]}/`}>{contact}</Link>,
      chosen: location === "contact",
    },
  ]

  return (
    <React.Fragment>
      <div
        className={`${styles.wrapper}`}
        id="header-mobile"
        style={{
          backgroundColor: `rgba(30,55,101,${scrollPerc * 0.8})`,
          height: `${120 - 45 * scrollPerc}px`,
        }}
      >
        {!openMenu && contrast && scrollPerc < 0.5 ? (
          <Link
            className={styles.logo_link}
            to={`${
              language === "en"
                ? "https://www.interficie.com/"
                : `/${language}/`
            }`}
          >
            <img src={Logo} className={styles.logo} />
          </Link>
        ) : location === "home" ? (
          <Link
            className={styles.logo_link}
            to={`${
              language === "en"
                ? "https://www.interficie.com/"
                : `/${language}/`
            }`}
          >
            <img src={LogoWhite} className={styles.logo} />
          </Link>
        ) : (
          <Link
            className={styles.logo_link}
            to={`/${language === "en" ? "" : language}`}
          >
            <img src={LogoWhiteNA} className={styles.logo} />
          </Link>
        )}
        <button
          aria-label={"menu-button"}
          onClick={() => clickedMenu(!openMenu)}
          className={`${styles.hamburger} ${styles.hamburger__spin} ${
            openMenu ? styles.is_active : ""
          }`}
          type="button"
        >
          <span className={styles.hamburger_box}>
            <span
              className={`${
                styles[`hamburger_inner${contrast ? "_contrast" : ""}`]
              } ${!scroll ? styles["white_hamburguer"] : ""} `}
            ></span>
          </span>
        </button>
      </div>
      {openMenu ? (
        <Menu
          options={options}
          click={clickedMenu}
          langSelector={urls}
          langActive={language}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  )
}

HeaderMobile.propTypes = {
  clickMenu: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  contrast: PropTypes.bool,
}

HeaderMobile.defaultProps = {
  contrast: false,
}

export default HeaderMobile
